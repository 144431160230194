import dayjs from 'dayjs'
import {CustomerActionRequest} from 'src/enums'
import i18n from 'src/i18n'

export const toCent = (decimalString: string): number | null => {
  const decimalNumber = parseFloat(decimalString)

  if (isNaN(decimalNumber)) {
    return null // Retourne null si la conversion échoue
  }

  const cents = Math.floor(decimalNumber * 100)
  return cents
}

export const cents_to_currency = (
  cents: number,
  currencyCode: string,
  minimumFractionDigits: number | undefined = undefined,
) => {
  return new Intl.NumberFormat(window.navigator.language, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: minimumFractionDigits,
  }).format(cents / 100)
}

export const isPast = (date: number): boolean => {
  return Date.now() > date * 1000
}

export const getExtendedDate = (date: number, extendDays: number): number => {
  const initialDate = dayjs(date)
  const newDate = initialDate.add(extendDays, 'day')
  return newDate.valueOf()
}

export const toDate = (value: number) => {
  if (value) {
    // Utilisez dayjs pour formater la date
    return dayjs(value * 1000).format('DD MMMM YYYY')
  }
}

export const uppercaseFirstLetter = (input: string): string =>
  input.charAt(0).toUpperCase() + input.slice(1)

export const customerRequestLabel = (
  customerRequest: CustomerActionRequest,
  isOrderConfirmable: boolean,
  isNonReturnable: boolean,
  isShipped: boolean,
) => {
  const {t} = i18n.global

  const labels = {
    isNotShipped: t('line_item.status.is_not_shipped'),
    nonReturnable: t('line_item.status.non_returnable'),
    exchange: t('line_item.status.exchange'),
    instantExchange: t('line_item.status.instant_exchange'),
    giftCard: t('line_item.status.gift_card'),
    vanillaRefund: t('line_item.status.vanilla_refund'),
    keep: t('line_item.status.keep'),
  }

  if (!isShipped) {
    return labels.isNotShipped
  }

  if (isNonReturnable) {
    return labels.nonReturnable
  }

  switch (customerRequest) {
    case CustomerActionRequest.EXCHANGE:
      return labels.exchange
    case CustomerActionRequest.INSTANT_EXCHANGE:
      return labels.instantExchange
    case CustomerActionRequest.GET_GIFT_CARD:
      return labels.giftCard
    case CustomerActionRequest.GET_REFUND:
      return labels.vanillaRefund
    case CustomerActionRequest.KEEP:
      return isOrderConfirmable ? '' : labels.keep
    default:
      return ''
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasSlotContent = (slotName: string | number, slots: any) => {
  return Boolean(!!slots[slotName] && slots[slotName]()[0].children.length > 0)
}
